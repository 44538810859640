import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import EmployeesSlide from '../components/careers/EmployeesSlide';
import styles from './careers.module.scss';
import { any, string } from 'prop-types';
import AvailableJobs from '../components/careers/AvailableJobs';
import useViewport from '../hooks/useViewport';
import Carousel from 'nuka-carousel';
import arrowLeft from '../images/black-arrow-left.svg';
import arrowRight from '../images/black-arrow-right.svg';

export const query = graphql`
  query {
    strapi {
      careerPage {
        id
        mainTitle
        introTitle
        introDescription
        introVideo {
          id
          name
          url
        }
        collageImage {
          url
        }
      }
      careerPageJobs {
        id
        jobTitle
        jobUrl
        jobDescription
        job_type {
          name
        }
      }
      jobTypes {
        name
      }
      careerPageTestimonials {
        image {
          url
        }
        name
        text
        position
        sortIndex
      }
    }
  }
`;

interface Props {
  data: {
    strapi: {
      careerPageJobs: Array<{
        jobTitle: string;
        jobUrl: string;
        jobDescription: string;
        job_type: {
          name: string;
        };
      }>;
      careerPage: {
        mainTitle: string;
        introTitle: string;
        introDescription: string;
        introVideo: {
          name: string;
          url: string;
        };
        collageImage: {
          url: string;
        };
      };
      jobTypes: Array<{
        name: string;
      }>;
      careerPageTestimonials: Array<{
        image: {
          url: string;
        };
        name: string;
        text: string;
        position: string;
        sortIndex?: number;
      }>;
    };
  };
}

const Careers: React.FC<Props> = ({ data: { strapi: data } }: Props) => {
  const { isTablet } = useViewport();
  const { isMobile } = useViewport();
  data.careerPageTestimonials.sort((a, b) => a.sortIndex - b.sortIndex);

  return (
    <Layout lightNavbar={true}>
      <div className={styles.heroWrap}>
        <video
          autoPlay
          muted
          loop
          playsInline
          width="100%"
          src={data.careerPage.introVideo.url}
        ></video>
        <div className={styles.darkBg}></div>
        <div className={styles.heroContentWrap}>
          <h1>{data.careerPage.introTitle}</h1>
          <div className={styles.whiteDivider}></div>
          <p>{data.careerPage.introDescription}</p>
        </div>
      </div>
      <h2 className={styles.pageMainTitle}>{data.careerPage.mainTitle}</h2>
      <div className={styles.jobsWrap}>
        <div className={styles.filtersWrap}>
          <p>FILTER</p>
          {data.jobTypes.map((t, i) => (
            <div key={'b' + i}>
              <input
                key={'c' + i}
                type="checkbox"
                id={t.name}
                name={t.name}
                className="filter-checkbox"
              ></input>
              <label key={'d' + i} htmlFor={t.name}>
                {t.name}
              </label>
            </div>
          ))}
        </div>
        <div className={styles.jobsList}>
          {data.careerPageJobs.map((j, i) => (
            <AvailableJobs
              key={'s' + i}
              jobName={j.jobTitle}
              jobDescription={j.jobDescription}
              link={j.jobUrl}
              jobType={j.job_type.name}
            />
          ))}
        </div>
      </div>
      <div className={styles.collageWrap} data-left-badge="WORK" data-right-badge={'& PLAY'}>
        <img src={data.careerPage.collageImage.url} alt="" />
      </div>
      <div className={styles.carouselWrap}>
        <Carousel
          wrapAround
          slideWidth={isTablet ? '340px' : '1200px'} // pixels required because of Carousel lib
          height={'fit-content'}
          slidesToShow={isTablet ? 1 : 1}
          slideIndex={0}
          className={styles.sliderBottom}
          renderBottomCenterControls={() => <></>}
          renderCenterLeftControls={({ previousSlide }) => (
            <div className={styles.switchBtn} onClick={previousSlide}>
              <img src={arrowLeft} alt="arrow left" className={styles.slideImgLeft} />
            </div>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <div className={styles.switchBtn} onClick={nextSlide}>
              <img src={arrowRight} alt="arrow right" className={styles.slideImgRight} />
            </div>
          )}
        >
          {data.careerPageTestimonials.map((s, i) => (
            <EmployeesSlide
              key={'a' + i}
              imageSrc={s.image?.url}
              authorName={s.name}
              testimonialText={s.text}
              employeeName={s.name}
              employeePosition={s.position}
            />
          ))}
        </Carousel>
      </div>
    </Layout>
  );
};

setTimeout(function () {
  const allCheckboxes = document.querySelectorAll('.filter-checkbox');
  const allJobs = document.querySelectorAll('#single-job-wrap');
  allCheckboxes.forEach((c) => {
    c.addEventListener('change', (e) => {
      allJobs.forEach((jb) => {
        if (allCheckboxes.length) {
          for (let i = 0; i < allCheckboxes.length; i++) {
            if (allCheckboxes[i].checked == true) {
              jb.getAttribute('data-job-type');
              if (jb.getAttribute('data-job-type') == allCheckboxes[i].getAttribute('id')) {
                jb.style.display = 'flex';
                break;
              } else {
                jb.style.display = 'none';
              }
            }
          }
        }
      });
      const allCheckedCheckboxes = [];
      allCheckboxes.forEach((b) => (b.checked ? allCheckedCheckboxes.push(b) : ''));
      allCheckedCheckboxes.length < 1 ? allJobs.forEach((jb) => (jb.style.display = 'flex')) : '';
    });
  });
}, 3000);

export default Careers;
